.App {
  text-align: center;
  font-family: Tahoma;
  vertical-align: top;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Illustration-box {
  font-size: 0px;
}

.Illustration {
  width: 98vmin;
  border-radius: 5vmin;
}

.Spinning-box {
  width: 98vmin;
  height: calc(98vmin * 0.5625);

}

.Spinning-helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .Spinning-wheel {
    animation: App-logo-spin infinite 1s linear;
    height: 20vmin;
    width: 20vmin;
    vertical-align: middle;
    max-height: calc(98vmin * 0.5625);
    filter: invert(100%);
  }
}

.Text-container {
  width: 90vmin;
  display: flex;
}

.Desc-container {
  width: 100%;
}

.Para-container {
  width: calc(10px + 50vmin);
  display: inline-block;
}

.Description {
  text-align: left;
  font-weight: 100;
  font-size: calc(8px + 1vmin);
  font-family: Georgia;
  width: 100%;
}

.Title {
  text-align: center;
  font-weight: 800;
  width: calc(100vmin - 4vmin);
  font-size: calc(2px + 3vmin);
}

.App-header {
  background-color: #2c2c2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Button-container {
  margin: 10px;
  width: 20vmin;
  justify-content: right;
}

.Button-default {
  background-color: #539b91;
  font-family: Tahoma;
  color: white;
  font-size: calc(2px + 1.5vmin);
  text-align: center;
  margin: 5px 5px;
  border: none;
  font-weight: 800;
  border-radius: 1vmin;
  width: 20vmin;
  height: calc(2px + 6.5vmin);
  float: right;
}
.Button-default:hover {
  background-color: #a4cac5;
}
.Button-default:active {
  background-color: #d6fffa;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
